import React , {useEffect,useState} from 'react'
import './Prediction.css'
import axios from 'axios'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import { toast } from 'react-custom-alert';
import { CircularProgress } from "@mui/material";



export default function Prediction({isClick,setisClick,isNew,setisNew,visible,globaluuid,setglobaluuid, apilink}) {  
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const [secLeft,setsecLeft] = useState(59);
  const [closeLeft,setcloseLeft] = useState(0);
  // eslint-disable-next-line
  const [showPopup, setShowPopup] = useState(false);
  const [closeTime, setcloseTime] = useState(false);
  const [checktrade, setchecktrade] = useState(true);
  const [loading, setloading] = useState(false);
  const [allowTrade, setallowTrade] = useState(false);
  const [updownbtn,setupdownbtn] = useState(0);
  const [nopred,setnopred] = useState(9);
  const [outpred,setoutpred] = useState(3);
  const [nextTime,setnextTime] = useState(0);
  const [lastData,setlastData] = useState('');
  const [bidData,setbidData] = useState('');
  const [winlength,setwinlength] = useState(0);
  const [myLevel,setMyLevel] = useState(0);
  const supertime = ['12:00','12:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00']
  const UniDate = new Date();
  const UniHourset = UniDate.toLocaleTimeString([], { hour: '2-digit' , hour12: true}).replace(/ am| pm| AM| PM/i, '');
  const UniHours = UniHourset === '00'?'12':UniHourset;
  const UniMin = (UniDate.getMinutes() >= 30?'30':'00');
  const SetUni = (UniHours.length === 1?'0'+UniHours : UniHours) + ':' + (UniMin.length === 1?'0'+UniMin : UniMin);
  // var bidData = '';
  // var lastData = '';
  const [win,setwin] = useState(false);
  const [predictionsByDate, setPredictionsByDate] = useState({});
  // var apilink = 'https://test.projaat.com/api';
  // var apilink = 'http://localhost:8080/api';
  // var apilink = 'http://192.168.0.105:8080/api';

  

// setisClick(true);

// fetch('https://fcsapi.com/api-v3/forex/history?symbol=XAU/USD&period=1m&access_key=C9KAsYLFLD99gZWXSL75&level=1')
//           .then((res) => res.json())
//           .then((data) => {
//             const ReverseData = Object.values(data.response).reverse();
//             const quicktime = new Date();
//             quicktime.setHours(quicktime.getHours(),(quicktime.getMinutes())+30,0,0);
//             const quickmonth = (quicktime.getMonth().toString().length === 1? ('0' + (Number(quicktime.getMonth()) + 1)):(Number(quicktime.getMonth())));
//             const formattedTime = quicktime.getFullYear()+'-'+ (quickmonth) +'-'+(quicktime.getDate().toString().length === 1? ('0' + quicktime.getDate()):(quicktime.getDate()))+' '+(quicktime.getHours().toString().length === 1? ('0' + quicktime.getHours()):(quicktime.getHours()))+':'+(quicktime.getMinutes().toString().length === 1? ('0' + quicktime.getMinutes()):(quicktime.getMinutes()))+':00'
//             const tmindex = ReverseData.findIndex(obj => obj.tm === formattedTime);
//             console.log(tmindex);
//           });

const [XAU,setXAU] = useState(0);
// useEffect(() => {
//   if (XAU === 0) {
//     fetch('https://api.gold-api.com/price/XAU')
//       .then((res) => res.json())
//       .then((data) => {
//         setXAU(data.price);
//         document.getElementById('insightnumber').value = data.price; // Update the input field directly
//       })
//       .catch((err) => console.error('Error fetching XAU data:', err));
//   }
// }, [XAU]);

function storeTrade(e){
    const quicktime = new Date();
    quicktime.setHours(quicktime.getHours(),(quicktime.getMinutes())+30,0,0);
    const quickmonth = (quicktime.getMonth().toString().length === 1? ('0' + (Number(quicktime.getMonth()) + 1)):(Number(quicktime.getMonth())));
    const formattedTime = quicktime.getFullYear()+'-'+ (quickmonth) +'-'+(quicktime.getDate().toString().length === 1? ('0' + quicktime.getDate()):(quicktime.getDate()))+' '+(quicktime.getHours().toString().length === 1? ('0' + quicktime.getHours()):(quicktime.getHours()))+':'+(quicktime.getMinutes().toString().length === 1? ('0' + quicktime.getMinutes()):(quicktime.getMinutes()))+':00'
    const quickLasttime = new Date();
    const quickLastmonth = (quickLasttime.getMonth().toString().length === 1? ('0' + (Number(quickLasttime.getMonth()) + 1)):(Number(quickLasttime.getMonth())));
    const formattedLastTime = quickLasttime.getFullYear()+'-'+ (quickLastmonth) +'-'+(quickLasttime.getDate().toString().length === 1? ('0' + quickLasttime.getDate()):(quickLasttime.getDate()))+' '+(quickLasttime.getHours().toString().length === 1? ('0' + quickLasttime.getHours()):(quickLasttime.getHours()))+':'+(quickLasttime.getMinutes().toString().length === 1? ('0' + quickLasttime.getMinutes()):(quickLasttime.getMinutes()))+':00'
    // const formattedRoundTime = quickLasttime.getFullYear()+'-'+ (quickLastmonth) +'-'+(quickLasttime.getDate().toString().length === 1? ('0' + quickLasttime.getDate()):(quickLasttime.getDate()))+' '+(quickLasttime.getHours().toString().length === 1? ('0' + quickLasttime.getHours()):(quickLasttime.getHours()))+':'+(quickLasttime.getMinutes() <= 30? '30':'00')+':00'
    const formattedTimeNew = quicktime.getUTCFullYear()+'-'+ (quickmonth) +'-'+(quicktime.getUTCDate().toString().length === 1? ('0' + quicktime.getUTCDate()):(quicktime.getUTCDate()))+' '+(quicktime.getUTCHours().toString().length === 1? ('0' + quicktime.getUTCHours()):(quicktime.getUTCHours()))+':'+(quicktime.getUTCMinutes().toString().length === 1? ('0' + quicktime.getUTCMinutes()):(quicktime.getUTCMinutes()))+':00'
    const formattedLastTimeNew = quickLasttime.getUTCFullYear()+'-'+ (quickLastmonth) +'-'+(quickLasttime.getUTCDate().toString().length === 1? ('0' + quickLasttime.getUTCDate()):(quickLasttime.getUTCDate()))+' '+(quickLasttime.getUTCHours().toString().length === 1? ('0' + quickLasttime.getUTCHours()):(quickLasttime.getUTCHours()))+':'+(quickLasttime.getUTCMinutes().toString().length === 1? ('0' + quickLasttime.getUTCMinutes()):(quickLasttime.getUTCMinutes()))+':00'

    const saveData = {
      Last:formattedLastTime,
      Predictions:e,
      Formattime:formattedTime,
      Realtime:formattedTimeNew+'/'+formattedLastTimeNew,
      Insights:document.getElementById('insightnumber').value,
    }
    const encodesaveData = JSON.stringify(saveData);
    axios.post(apilink+'/updatetrade', {encodesaveData,globaluuid})
    .then(res => {
    //  console.log(res.data);
toast.success('Successfully Submitted')
setXAU(0);

     })
    .catch(err => console.log(err));
    setlastData('');
    setbidData('');


}

const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const currentDate = formatDate(new Date());
// eslint-disable-next-line
  const [PredictionData, setPredictionData] = useState({});

  useEffect(() => {
    axios.post(apilink+'/timeline', {globaluuid})
            .then(res => {
              if ( res.data && res.data[currentDate] && Object.keys(res.data[currentDate]).length > 0) {
              setPredictionData(res.data[currentDate]);
              const globalData = res.data;
              const indata = Object.values(globalData[currentDate]).map(entry => entry.result)
              const filterdata = indata.filter(result => result === true);
              setwinlength(filterdata.length);
              } 
            })
              .catch(err => console.log(err));

              axios.post(apilink+'/level', {globaluuid})
              .then(res => {
                setMyLevel(res.data.status);
              })
                .catch(err => console.log(err));

             
  }, [globaluuid,apilink,currentDate]);


  useEffect(() =>{
    

    if(isNew){
      setisNew(false);
      setupdownbtn(0);
    }
    if (isClick || checktrade){
      var timer;
  var finalBid = '';
  var finalLast = '';
  var finalwin = '';
  var finaltime = '';
  var finallasttime = '';
  var finalInsights = '';
  var finalupdown = 0;
  var resultDone = false;

      timer= setInterval(() =>{
        const currentTime = new Date();
        const currentMinute = currentTime.getMinutes();
        const isTime = currentMinute === 0 || currentMinute === 30; // Check if the current minute is 0 or 30
        const currentSec = currentTime.getSeconds();

        setsecLeft(((60-currentSec).toString().length === 2 ?(''):('0')) + (60- currentSec));
        console.log(secLeft)

        // setsecLeft(secLeft - 1);
        // if(secLeft === 0){
        if(!isTime && !checktrade){
          setcloseTime(true);
          setsecLeft(59);
          clearInterval(timer);
          setisClick(false);
          setnopred(nopred - 1);
          // fetch('https://fcsapi.com/api-v3/forex/history?symbol=XAU/USD&period=1m&access_key=C9KAsYLFLD99gZWXSL75&level=1')
          // .then((res) => res.json())
          // .then((data) => {
          //   const ReverseData = Object.values(data.response).reverse();
          //   setbidData(ReverseData[1].c);
          //   setlastData(ReverseData[2].c);
          //   // bidData = ReverseData[1].c;
          //   // lastData = ReverseData[2].c;
          //   console.log('noooo'+lastData);
          // })
          

          //function was here
          // if(updownbtn !== 0){

          //storeTrade function

        //     function storeTrade(){

        //   // fetch(`https://fcsapi.com/api-v3/forex/history?symbol=XAU/USD&period=1m&access_key=C9KAsYLFLD99gZWXSL75&level=1&_=${new Date().getTime()}`)
        //   // .then((res) => res.json())
        //   // .then((data) => {
        //     // const ReverseData = Object.values(data.response).reverse();
        //     const quicktime = new Date();
        //     quicktime.setHours(quicktime.getHours(),(quicktime.getMinutes())+29,0,0);
        //     const quickmonth = (quicktime.getMonth().toString().length === 1? ('0' + (Number(quicktime.getMonth()) + 1)):(Number(quicktime.getMonth())));
        //     const formattedTime = quicktime.getFullYear()+'-'+ (quickmonth) +'-'+(quicktime.getDate().toString().length === 1? ('0' + quicktime.getDate()):(quicktime.getDate()))+' '+(quicktime.getHours().toString().length === 1? ('0' + quicktime.getHours()):(quicktime.getHours()))+':'+(quicktime.getMinutes().toString().length === 1? ('0' + quicktime.getMinutes()):(quicktime.getMinutes()))+':00'
        //     const quickLasttime = new Date();
        //     const quickLastmonth = (quickLasttime.getMonth().toString().length === 1? ('0' + (Number(quickLasttime.getMonth()) + 1)):(Number(quickLasttime.getMonth())));
        //     const formattedLastTime = quickLasttime.getFullYear()+'-'+ (quickLastmonth) +'-'+(quickLasttime.getDate().toString().length === 1? ('0' + quickLasttime.getDate()):(quickLasttime.getDate()))+' '+(quickLasttime.getHours().toString().length === 1? ('0' + quickLasttime.getHours()):(quickLasttime.getHours()))+':'+(quickLasttime.getMinutes().toString().length === 1? ('0' + quickLasttime.getMinutes()):(quickLasttime.getMinutes()))+':00'
        //     const formattedRoundTime = quickLasttime.getFullYear()+'-'+ (quickLastmonth) +'-'+(quickLasttime.getDate().toString().length === 1? ('0' + quickLasttime.getDate()):(quickLasttime.getDate()))+' '+(quickLasttime.getHours().toString().length === 1? ('0' + quickLasttime.getHours()):(quickLasttime.getHours()))+':'+(quickLasttime.getMinutes() <= 30? '30':'00')+':00'
        //     // finalBid = ReverseData[1].c;
        //     // finalLast = ReverseData[2].c;
        //     const saveData = {
        //       // Last:finalBid,
        //       Last:formattedLastTime,
        //       Predictions:updownbtn,
        //       Formattime:formattedTime,
        //       Realtime:formattedRoundTime,
        //       Insights:document.getElementById('insightnumber').value,
        //     }
        //     const encodesaveData = JSON.stringify(saveData);
        //     axios.post(apilink+'/updatetrade', {encodesaveData,globaluuid})
        //     .then(res => {
        //      console.log(res.data);
        // toast.success('Successfully Submitted')
        // setXAU(0);
        
        //      })
        //     .catch(err => console.log(err));
        //     setlastData('');
        //     setbidData('');

        //     // setbidData(ReverseData[1].c);
        //     // setlastData(ReverseData[2].c);
        //   // })
        // }
          
        } 
        const functionExecute =()=>{
          axios.post(apilink+'/predictions', {globaluuid})
          .then(res => {
            // console.log(res.data);
            console.log('getting data');
            // setPredictionsByDate(res.data[0].predictions);
            setPredictionsByDate(res.data);
      
        if (finalupdown === 1) {
          console.log('cool'+finalBid);

          setShowPopup(true);
          if (finalBid > finalLast) {
            setoutpred(outpred + 1)
            setwin(true);
            finalwin = true;
          } else {
            setwin(false);
            finalwin = false;
          }
        } else if (finalupdown === 2) {
          setShowPopup(true);
          if (finalBid <= finalLast) {
            setShowPopup(true);
            setoutpred(outpred + 1)
            setwin(true);
            finalwin = true;
          } else {
            setwin(false);
            finalwin = false;
          }
        }

        console.log(updownbtn);
        if (finalupdown !== 0){
        // const currentDate = new Date().toLocaleDateString('en-GB');
        const currentDate = formatDate(new Date(finaltime));
        // const cTime = new Date(finallasttime);
        // const currentMinute = cTime.getMinutes();
        const addTime = new Date(finallasttime);
        // addTime.setHours(addTime.getHours(),(currentMinute > 30?(60):(30))-30,0,0) //addTime.getMinutes()

        const currentTime = addTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' , hour12: true}).replace('00:', '12:').replace(/AM|PM/i, match => match.toLowerCase());

        const newPrediction = {
          price: finalBid,
          prediction: finalupdown,
          previous: finalLast,
          result: finalwin,
          insights: finalInsights,
          level: myLevel,
      };

      // console.log('super '+finalBid);

       // Update the predictionsByDate state variable
       setPredictionsByDate((prevPredictionsByDate) => {
        // Create a copy of the previous predictions
        const updatedPredictions = { ...prevPredictionsByDate };

        // Initialize a new object for the current date if it doesn't exist
        if (!updatedPredictions[currentDate]) {
            updatedPredictions[currentDate] = {};
        }

        // Add the new prediction for the current time
        updatedPredictions[currentDate][currentTime] = newPrediction;
        const encodedPredictions = JSON.stringify(updatedPredictions);
    // console.log(encodedPredictions);
    axios.post(apilink+'/storepredictions', {encodedPredictions,globaluuid})
          .then(res => {
            // console.log(res.data);
            setloading(false);
            resultDone = false;
            // setPredictionsByDate(res.data[0].predictions);
          })
          .catch(err => console.log(err));
        return updatedPredictions;

    });
    
  }else{
    setloading(false);
  }
})
  .catch(err => console.log(err));
        } //function ends here


        console.log('ff')
        axios.post(apilink+'/level', {globaluuid})
        .then(res => {
        console.log('level data');
        const tradeData = res.data.tradetime;
        setallowTrade(tradeData === '{}');
        if(tradeData !== "{}"){
        const decodetradeData = JSON.parse(tradeData);
        // const formattedTime = decodetradeData['Formattime'];
        // const formattedLastTime = decodetradeData['Last'];
        // finaltime = new Date(decodetradeData['Formattime']);
        finaltime = new Date(decodetradeData['Formattime']);
        finallasttime = new Date(decodetradeData['Last']);
        finalInsights = decodetradeData['Insights'];
        const realTime = decodetradeData['Realtime'];
        const currentTime = new Date();
        // finaltime.setHours(finaltime.getHours(),(finaltime.getMinutes())-1,0,0);
        console.log('time - '+ (currentTime > realTime) , currentTime)
        console.log('timer - '+ (currentTime >= finaltime), finaltime)
        if(currentTime >= finaltime && !resultDone){
          resultDone = true;
          setloading(true);
        // finalLast = decodetradeData['Last'];
        finalupdown = decodetradeData['Predictions'];

        function fetchApi(retries = 3){
        fetch(`https://fcsapi.com/api-v3/forex/history?id=1984&period=1m&from=${realTime.split('/')[1].replace(' ', 'T')}&to=${realTime.split('/')[0].split(' ')[0] + 'T24:00:00'}&access_key=CPnde73RmRGmt7roCSyII&_=${new Date().getTime()}`)
        // fetch(`https://fcsapi.com/api-v3/forex/history?symbol=XAU/USD&period=1m&access_key=C9KAsYLFLD99gZWXSL75&level=1&_=${new Date().getTime()}`)
          .then((res) => res.json())
          .then((data) => {
            if (!data || !data.response) {
              console.log('api retries')
              fetchApi(retries - 1)
            }else{
            // console.log(`https://fcsapi.com/api-v3/forex/history?id=1984&period=1m&from=${realTime.split('/')[1].replace(' ', 'T')}&to=${realTime.split('/')[0].split(' ')[0] + 'T24:00:00'}&access_key=CPnde73RmRGmt7roCSyII&_=${new Date().getTime()}`)
            console.log('test 2')
            const ReverseData = Object.values(data.response).reverse();
            const tmindex = ReverseData.findIndex(obj => obj.tm === realTime.split('/')[0]);
            const tmLastindex = ReverseData.findIndex(obj => obj.tm === realTime.split('/')[1]);
            if(tmindex !== -1){
              finalBid = ReverseData[tmindex].c;
              finalLast = ReverseData[tmLastindex].c;
            // bidData = ReverseData[1].c;
            // lastData = ReverseData[2].c;
            console.log('success'+finalBid);
            functionExecute();
            }else{
              finalBid = ReverseData[1].c;
              finalLast = ReverseData[2].c;
              functionExecute();
            console.log('failed index : '+ReverseData[0].tm);

            }
          }
          })
        } // function ends

        fetchApi();

        }else {
          setloading(false);
        }
      }else{
        setloading(false);
      }
      setchecktrade(false);
  
        })
        .catch(err => console.log(err));

      },1000) //timer ends here
  
      return ()=> clearInterval(timer);

    } else if (closeTime) {
      // const currentTime = new Date();
      // const currentMinute = currentTime.getMinutes();
      // setsecLeft(30 - currentMinute)
      // console.log(secLeft);
    }
     else{
      clearInterval(timer);
      setsecLeft(59);    
    };
    

  },[checktrade,secLeft,isClick,setisClick,updownbtn,nopred,outpred,isNew,setisNew,closeTime,apilink,globaluuid,predictionsByDate,win,lastData,bidData,myLevel]);


  useEffect(() => {
    const checkTime = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();

      // Check if current time is within the specified intervals (12pm - 8pm)
      if (currentHour >= 12 && currentHour <= 19) {
        const isTime = currentMinute === 0 || currentMinute === 30; // Check if the current minute is 0 or 30

        if (isTime) {
          if (!isClick) {
          setloading(true);
            fetch('https://api.gold-api.com/price/XAU')
              .then((res) => res.json())
              .then((data) => {
                const roundedPrice = Math.round(data.price);
                setXAU(roundedPrice);
                document.getElementById('insightnumber').value = roundedPrice; // Update the input field directly
              })
              .catch((err) => console.error('Error fetching XAU data:', err));
            }
          setisClick(true); // Set isClick to true at the specified times
          setcloseTime(false);
          // Automatically turn off isClick after 1 minute
          // setTimeout(() => {
          //   setisClick(false);
          // }, 60000); 
        } else {
          setisClick(false); // not sure
          const currentTime = new Date();
          const currentMinute = currentTime.getMinutes();
          const currentSec = currentTime.getSeconds();
          const addTime = new Date();
          addTime.setHours(addTime.getHours(),(currentMinute > 30?(30):(0))+30,0,0) //addTime.getMinutes()
          setnextTime(addTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true}).replace('00:', '12:').replace(/AM|PM/i, match => match.toLowerCase()));
          setcloseTime(true);
          if (currentMinute < 30){
            setcloseLeft( ((29-currentMinute).toString().length === 2 ?(''):('0')) + (29 - currentMinute) + ':'  + ((60-currentSec).toString().length === 2 ?(''):('0')) + (60- currentSec))
          } else {
            setcloseLeft( ((59-currentMinute).toString().length === 2 ?(''):('0')) + (59 - currentMinute)   + ':' + ((60-currentSec).toString().length === 2 ?(''):('0')) + (60- currentSec))
          }
          
        }
      } else {
        setcloseTime(false);
      }
    };
    // Run checkTime immediately when the component is mounted
    checkTime();

    // Check the time every minute
    const intervalId = setInterval(checkTime, 1000); // 60000 milliseconds = 1 minute

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [setisClick,XAU,isClick]);


  // const upClick=(e)=>{
  //   console.log('working');
  //   e.preventDefault();
  //   e.target.style.backgroundColor = 'rgb(75, 192, 192)'
  //   console.log(e.target);

  // }


  const upClick =()=>{
    if(allowTrade){
    if(!!document.getElementById('insightnumber').value && document.getElementById('insightnumber').value <= (XAU+50)){
      if(document.getElementById('insightnumber').value > XAU){
        if (updownbtn === 1) {
          // setupdownbtn(0);
          // storeTrade(0);
        } else{
          setupdownbtn(1);
          storeTrade(1)
        }
        // toast.success('Processing | please wait till time ends')
      } else {
        toast.warning('Please increase the price')
      }

    } else{
      toast.warning(!document.getElementById('insightnumber').value?'Please enter price insights':'You can only increase upto $50')
    }
  } else {
    // toast.warning('try again later')
  }
  }
  const downClick =()=>{
    if(allowTrade){
    if(!!document.getElementById('insightnumber').value && document.getElementById('insightnumber').value >= (XAU-50)){
      if(document.getElementById('insightnumber').value < XAU){
      if (updownbtn === 2) {
        // setupdownbtn(0);
        // storeTrade(0);
      } else{
      setupdownbtn(2);
      storeTrade(2)
      }
      // toast.success('Processing | please wait till time ends')
    } else {
      toast.warning('Please decrease the price')
    }
    }else{
      toast.warning(!document.getElementById('insightnumber').value?'Please enter price insights':'You can only decrease upto $50')
    }
  } else {
    // toast.warning('try again later')
  }
  }
  // eslint-disable-next-line
  const closepop =()=>{
    setShowPopup(false);
    window.location.reload();
  }


  return (
    // <div style={visible === 'true'?{display:'block',}:{display:'none'}} >
    <div style={{display: visible === 'true'?'block':'none',width: window.screen.width > 600 ? '100%' : '100%',marginLeft:window.screen.width > 600 ?'0px':(isMobile?'0%':'-20%')}}>
      {/* {showPopup && (
      <div className="popup-overlay">
        <div className="popup">
          {win ? (
            <p>Your prediction is right</p>
          ) : (
            <p>Your prediction is wrong</p>
          )}
          <div style={{display:'flex',justifyContent:'center'}}>
        <button onClick={closepop} style={{border:'0px',borderRadius:'5px',backgroundColor:'black',color:'white',padding:'5px'}}>Close</button>
          </div>
        </div>
        </div>
      )} */}
      {loading && (
      <div className="popup-overlay">
        <div className="popup">
        <CircularProgress color="primary" />    
        </div>
        </div>
      )}
      <img src={isClick?"frameTopOff.png":"frameTopOn.png"} alt="" className="pre-top" />
      <div className='out-pred'>
      <div className='time-down' style={{display:isClick?'none':'flex'}}>
            <h5 >{isClick?('Seconds Left to Close'):(nextTime === 0?('Next Prediction 12:00 pm'):('Next Insights '  + nextTime))}</h5>
            <h5 style={{display:isClick?'none':'block'}}>Remaining Insights</h5>
            </div>
     <div className='prediction'>
        <div className='time-pred' style={{width:isClick?'22%':'50%'}}>
            <h2 >{isClick?('00:'+secLeft):(closeLeft === 0?'Closed':closeLeft)}</h2>
            {/* <div className='time-down'>
            <h5 >{isClick?('Seconds Left to Close'):('Next Prediction ' + nextTime)}</h5>
            </div> */}
        </div>
        {/* <div style={{width:'1px',background:'#00e8dc'}}></div> */}
        <div style={{display:isClick?'none':'block'}} className='rem-pred'>
            {/* <h2 >{nopred}</h2> */}
            {/* <h2 >{15 - (PredictionData ? Object.keys(PredictionData).length : 0)}</h2> */}
            <h2 >{14-(supertime.indexOf(SetUni))}</h2>
            {/* <div className='rem-down'>
            <h5 >Remaining Prediction</h5>
            </div> */}
        </div>

     </div>
     {isClick !== true? (<div className='bottom-pred' style={{display:'none'}}>
      <img style={{height:'25px',width:'25px',margin:'15px'}} src="https://cdn-icons-png.flaticon.com/128/14606/14606478.png" alt="" />
      <h4>{winlength}/15 Prediction is Correct</h4>
     </div>):(<div>
     {/* <div style={{height:'1px',width:'100%',background:'rgb(104, 104, 104)'}}></div> */}
     <div className='up-down'>
      <button className='up-btn' onClick={upClick} style={{backgroundColor: updownbtn === 1? ('rgb(75, 192, 192)'):('#32ba7b'),color: updownbtn === 1 ? ('#ffffff') : ('#ffffff')}}>
        Up
        <ArrowOutwardIcon/>
      </button>
      <div className="price-tb-divv">
        <span>Price Insights</span>
        <input id='insightnumber' type="number" placeholder='0'  />
      </div>
      <button className='down-btn'onClick={downClick} style={{backgroundColor: updownbtn === 2? ('rgb(75, 192, 192)'):('#e8684e'),color: updownbtn === 2 ? ('#ffffff') : ('#ffffff')}}>
        Down
        <SouthEastIcon/>
      </button>
     </div>
     </div>)}
     
     <img src={isClick?"frameBotOff.png":"frameBotOn.png"} alt="" className="pre-bot" />
     
     </div>
    </div>
  )
}
